import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoAddEditComponent } from './components/video-add-edit/video-add-edit.component';
// tslint:disable-next-line: max-line-length
import {  deletevideomodal, playvideomodal, videoaddnotes, VideoListComponent, viewvideodailog } from './components/video-list/video-list.component';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from './modules/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { ListingModule } from 'listing-angular7';
// import { CKEditorModule } from 'ngx-ckeditor';
import { CKEditorModule } from 'ng2-ckeditor';
import { SafePipe } from './safe.pipe';
import { LoaderService } from './services/loader.service';
import { ResolveService } from './services/resolve.service';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderInterceptor } from './services/loader.interceptor';
import { VideobucketlistComponent } from './components/videobucketlist/videobucketlist.component';
import { PlayVideoComponent } from './components/play-video/play-video.component';
import { JwPaginationModule } from 'jw-angular-pagination';

@NgModule({
  declarations: [
    AppComponent,
    VideoAddEditComponent,
    VideoListComponent,
    SafePipe,
    LoaderComponent,
    viewvideodailog,
    videoaddnotes,
    VideobucketlistComponent,
    PlayVideoComponent,
    playvideomodal,
    deletevideomodal
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    DemoMaterialModule,
    RouterModule,
    ListingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DemoMaterialModule,
    MatFormFieldModule,
    // ChartsModule,
    BrowserAnimationsModule,
    MatInputModule,
    CKEditorModule,
    JwPaginationModule
  ],
  entryComponents: [
    viewvideodailog,
    videoaddnotes,
    playvideomodal,
    deletevideomodal
  ],
  providers: [CookieService, LoaderService, ResolveService, LoaderInterceptor],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
