import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-video-add-edit',
  templateUrl: './video-add-edit.component.html',
  styleUrls: ['./video-add-edit.component.css']
})
export class VideoAddEditComponent implements OnInit {
  videodata: any = [{ youtube_link: '', video_title: '', video_description: '', video_priority: '', status: '' }];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public formdata: any;
  formfieldrefreshdata = null;
  formfieldrefresh: any;
  public filearry: any;
  safe_url: any = [];
  public FullFile = '';
  videotype_flag: any = [];
  //  fileobj = {
  //       name: 'youtube_link',
  //       prefix: 'image-' + Date.now(),
  //       path: 'betoparedesallvideos',
  //       baseurl: 'betoparedesallvideos',
  //       bucket: 'awsbackend-dev-patient-files-test',
  //       apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
  //       apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
  //     };
  public flag: any = false;
  constructor(
    public http: HttpClient,
    public activatedroute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public apiservice: ApiService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: 'video-library',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      // endpoint: 'api/add-video', //
      jwttoken: '',
      cancelroute: 'video-library',
      fields: [

        {
          label: 'Video Upload: ',
          name: 'video_file',
          type: 'file',
          prefix: 'image-' + Date.now(),
          path: 'betoparedesallvideos/',
          baseurl: 'betoparedesallvideos/',
          bucket: 'betoparedesallvideos',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',

        },
        {
          label: 'Upload Video Url',
          name: 'video_file_url',
          value: '',
          type: 'text',
          // validations: [
          //   { rule: 'required', message: 'Video Title is required' },

          // ]
        },
        {
          label: 'Video Title',
          name: 'video_title',
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Video Title is required' },

          ]
        },
        {
          label: 'Video Description',
          name: 'video_description',
          value: '',
          type: 'editor',
          validations: [
            { rule: 'required', message: 'Video Description is required' },

          ]
        },
        {
          label: 'Priority',
          name: 'video_priority',
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Priority is required' },

          ]
        },
        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
        },
      ]
    };
    if (this.activatedroute.snapshot.params._id != null) {
      this.formdata.successmessage = 'Updated Successfully'
    }
  }

  ngOnInit() {
    this.editadmin();
  }


  editadmin() {
    if (this.activatedroute.snapshot.params._id != null) {
      this.activatedroute.data.subscribe((res: any) => {
        console.log(res.resolvedata.result, 'edit data', res.resolvedata.result[0].video_file);
        this.formdata.fields[this.formdata.fields.length] = {
          label: 'id',
          name: 'id',
          type: 'hidden',
          value: this.activatedroute.snapshot.params._id

        },

          // this.videodata = [{ video_id: res.resolvedata.results.video_id, title: res.resolvedata.results.title, description: res.resolvedata.results.description, priority: res.resolvedata.results.priority, status: res.resolvedata.results.status }];
          // this.applyFilter(res.resolvedata.results.video_id, 0);
          this.flag = true;
        let formdata1 = {
          video_file: res.resolvedata.result[0].video_file,
          video_title: res.resolvedata.result[0].video_title,
          video_description: res.resolvedata.result[0].video_description,
          video_priority: res.resolvedata.result[0].video_priority,
          status: res.resolvedata.result[0].status,
        }

        this.formfieldrefreshdata = { formvaldata: formdata1 }

      });
    }
  };
  getFileDetails(event) {
    console.log(event, 'event');
    let filename = event.target.files[0].name;
    this.filearry = event.target.files[0];
    const myArray = filename.split(".");
    const firstarray = myArray[0].split(" ");
    console.log(firstarray.join(''))
    console.log(filename, myArray, 'filename', firstarray)
    this.FullFile = firstarray.join('') + '.' + myArray[1];
    console.log(this.FullFile, 'FullFile');

    // for (let i in firstarray) {
    //   const element = firstarray[i];
    //   console.log(element, 'element');
    // }

  };
  uploadvideo() {
    let UploadeURL = 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL'
    let data = { name: 'image-' + Date.now() + this.FullFile, bucket: 'awsbackend-dev-patient-files-test', type: "video/mp4", path: "betoparedesallvideos/" }
    this.apiservice.getDatafileupload(data, UploadeURL).subscribe((response: any) => {
      console.log(this.filearry, 'response', response);
      let requestdata = this.filearry;
      this.apiservice.getDatafileuploadput(requestdata, response.uploadURL).subscribe((response: any) => {
        console.log('eeee');

      })


    })
  };
  formsubmit() {
    console.log(this.videodata);
    // return
    let returnflag: boolean = false;
    if (this.flag === false) {
      if (this.videodata.length > 0) {
        for (const key in this.videodata) {
          // this.videodata[key].type = 'video';
          console.log(this.videodata[key].status);
          if (this.videodata[key].status === true) {
            this.videodata[key].status = 1;
          } else {
            this.videodata[key].status = 0;
          }
          if (this.videodata[key].youtube_link === '' || this.videodata[key].video_description === '' || this.videodata[key].video_title === '' && this.videotype_flag[key] === true) {
            returnflag = true;
          }
        }
      }

      console.log('data', this.videodata);
      let data = { data: this.videodata };

      console.log(data.data, 'dtata');
      if (!returnflag) {
        if (data.data.length > 0) {
          for (const i in data.data) {
            if (data.data[i].video_priority === '') {
              // data.data[i].priority = 1;
              delete data.data[i].video_priority;
            }
          }
        }
        this.apiservice.getDataWithoutToken(data, 'api/add-video').subscribe((data: any) => {
          console.log(data, 'result success');
          if (data.status === 'success') {
            // this.router.navigateByUrl('/gallery/video/gallery');
          }
        });
      } else {
        // this.openSnackBar('Please provide all the details', 'ok');
      }


    } else {
      if (this.videodata.length > 0) {
        for (const key in this.videodata) {
          // this.videodata[key].type = 'video';
          console.log(this.videodata[key].status);
          if (this.videodata[key].status === true) {
            this.videodata[key].status = 1;
          } else {
            this.videodata[key].status = 0;
          }
        }
      }

      const data = { data: this.videodata[0] };
      data.data._id = this.activatedroute.snapshot.params._id;
      console.log(data.data.video_priority);

      if (data.data.video_priority === '' || data.data.video_priority == null || typeof data.data.video_priority === 'undefined') {
        // data.data.priority = 1;
        delete data.data.video_priority;
        console.log(data);
      }
      console.log(data);
      console.log('data', this.videodata);
      // return
      this.apiservice.getDataWithoutToken(data, 'api/update_single_image_video').subscribe((data: any) => {
        console.log(data);
        if (data.status === 'success') {
          // this.router.navigateByUrl('/gallery/video/gallery');
        }
      });
    }
  };
  listenFormFieldChange(event: any) {
    console.log(event, 'betoparedesallvideos');
    if (event.field == 'fromsubmit' && event.fromval.video_file == '') {
      this._snackBar.open('Please Upload Video', 'Dismiss', {
        duration: 3000
      });
    }
    if (event.field == 'fromsubmit') {
      let data = {
        data: event.fromval
      }
      this.apiservice.getDataWithoutToken(data, 'api/add-video').subscribe((data: any) => {
        console.log(data, 'result success');
        if (data.status === 'success' && event.fromval.video_file) {
          this._snackBar.open('Video Added Successfully ', 'Dismiss', {
            duration: 3000
          });
          this.router.navigateByUrl('/video-library');
        }
      });
    }

    // if (event.fromval.video_file == '') {
    //   this._snackBar.open('Please Upload Video', 'Dismiss', {
    //     duration: 3000
    //   });
    // }
  };
}
