import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PlayVideoComponent } from './components/play-video/play-video.component';
import { VideoAddEditComponent } from './components/video-add-edit/video-add-edit.component';
import { VideoListComponent } from './components/video-list/video-list.component';
import { VideobucketlistComponent } from './components/videobucketlist/videobucketlist.component';
import { ResolveService } from './services/resolve.service';


const routes: Routes = [
  { path: 'video-add', component: VideoAddEditComponent },

  {
    path: 'video-library', component: VideoListComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'createdon_datetime', // data sort by first name
        },
        searchcondition: {
        }
      },
      endpoint: 'api/video-list', // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: 'video/bucket/list', component: VideobucketlistComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: '_id', // data sort by first name
        },
        searchcondition: {
        }
      },
      endpoint: 'api2/list-video', // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },

  {
    path: 'video-library/edit/:_id', component: VideoAddEditComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: { id: "_id" },
      },
      endpoint: "api/getonevideodata",
      api_url: environment.api_url,
    },
  },
  {
    path: 'playvideo/:id', component: PlayVideoComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: { id: "_id" },
      },
      endpoint: "api2/singlevideo",
      api_url: environment.api_url,
    },
  },
  {
    path: 'video/:id', component: PlayVideoComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: { id: "_id" },
      },
      endpoint: "api2/singlevideo",
      api_url: environment.api_url,
    },
  },
  {
    path: 'video/:id', component: VideoListComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: '_id', // data sort by first name
        },
        searchcondition: {
        }
      },
      endpoint: 'api/video-list', // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
    // pathMatch: 'full',
    // redirectTo: 'video-library',
  },
  {
    path: '**', component: VideoListComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'createdon_datetime', // data sort by first name
        },
        searchcondition: {
        }
      },
      endpoint: 'api/video-list', // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
    pathMatch: 'full',
    // redirectTo: 'video-library',
  }


];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
