import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Pipe, PipeTransform } from '@angular/core';
import videojs from 'video.js';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JwPaginationModule } from 'jw-angular-pagination';
export interface DialogData {
  data: any;
  videodata: any;
  video_file_name_url: any;
  video_file_name: any;
  _id: any;
  video_title: any;
  video_description: any;
}
// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({ name: 'safe' })

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.css']
})
export class VideoListComponent implements OnInit {
  color: ThemePalette = 'warn';
  public video_listtable: any = [];
  public safeYoutubeURL: SafeResourceUrl;
  public indexVal: any = 12;
  public videoloaderflag: boolean = false;
  // video Active
  dataSourceLength: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  pageOfItems: Array<any>;
  today: any
  selected: any;
  video_url: any = [];
  video_safe_url: any = [];
  video_play_pause: any = [];
  public searchForm: FormGroup;

  public video_time: any = ''
  public videoJsConfigObj = {
    preload: 'metadata',
    controls: true,
    autoplay: true,
    overrideNative: true,
    techOrder: ['html5', 'flash'],
    html5: {
      nativeVideoTracks: false,
      nativeAudioTracks: false,
      nativeTextTracks: false,
      hls: {
        withCredentials: false,
        overrideNative: true,
        debug: true
      }
    }
  };
  player: videojs.Player;
  items: any = [{ id: 1 }];



  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder

    // public loaderService: LoaderService,
    // public LoaderInterceptor: LoaderInterceptor
  ) {
    this.searchForm = this.formBuilder.group({
      start_datetime_unixstart: '',
      start_datetime_unixend: '',
      video_title_s: '',
      createdon_datetime: {}
    })

  }
  public video_listtablecount: any = 0;
  ngOnInit() {
    let data = {
      source: '',
      condition: {
      },
      sort: {
        type: 'desc',
        field: 'createdon_datetime'
      },
      'searchcondition': {}
    }
    console.log(data);

    this.Apiservice.getDataWithoutToken(data, 'api/video-list-count').subscribe((data: any) => {
      this.items = Array(data.count).fill(0).map((x, i) => ({ id: (i + 1) }));
      console.log();

      this.video_listtablecount = data.count;
      console.log(this.video_listtablecount);

    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.activatedRoute.data.forEach((res: any) => {
      console.log(res, 'video data');
      this.video_listtable = res.resolvedata.results.res; // data store from backend


    });

    if (this.activatedRoute.snapshot.params.id != null) {
      for (let n in this.video_listtable) {
        if (this.video_listtable[n]._id == this.activatedRoute.snapshot.params.id)
          this.clickOpenVideo(this.video_listtable[n])
      }
    }


    // });

  }
  onChangePage(pageOfItems: any) {
    // update current page of items
    console.log('llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll');

    console.log(pageOfItems);
    let data = {
      source: '',
      condition: {
        limit: pageOfItems[pageOfItems.length - 1]['id'],
        skip: pageOfItems[0]['id'] - 1
      },
      sort: {
        type: 'desc',
        field: 'createdon_datetime'
      },
      searchcondition: {}
    };
    console.log(data);

    this.Apiservice.getDataWithoutToken(data, 'api/video-list').subscribe((data: any) => {
      console.log(data);
      console.log(data, 'data');
      this.video_listtable = data.results.res; // data store from backend
      if (data.status === 'success') {
        this.videoloaderflag = false;
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    });
    this.pageOfItems = pageOfItems;
  }
  search() {
    this.videoloaderflag = true;
    let data = {
      condition: {
        limit: 10,
        skip: 0
      },
      sort: {
        type: 'desc',
        field: 'createdon_datetime'
      },
      searchcondition: {
        // createdon_datetime: this.searchForm.value['createdon_datetime']
      }
    };

    console.log(this.searchForm.value, 'this.searchForm.value');
    if (this.searchForm.value['start_datetime_unixstart'] != '' && this.searchForm.value['start_datetime_unixend'] != '') {

      data.searchcondition['createdon_datetime'] = {
        $gte: new Date(
          this.searchForm.value['start_datetime_unixstart']
        ).getTime(), $lte: new Date(this.searchForm.value['start_datetime_unixend']).getTime() +
          86399999
      }
    }

    if (this.searchForm.value['video_title_s'] != '') {
      // this.searchForm.value['type_array'] = [];
      data.searchcondition['video_title_s'] = this.searchForm.value['video_title_s']


      // this.searchForm.value['video_title_s'] = [this.searchForm.value['video_title_s']];
      // this.searchForm.value['type_search'] = '';
    }

    // return
    this.Apiservice.getDataWithoutToken(data, 'api/video-list').subscribe((data: any) => {
      console.log(data);
      console.log(data, 'data');
      this.video_listtable = data.results.res; // data store from backend
      if (data.status === 'success') {
        this.videoloaderflag = false;

      }
    });
  }
  reset() {
    console.log('reset');
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      start_datetime_unixstart: '',
      start_datetime_unixend: '',
      type_array: [''],
      createdon_datetime: {}
    })
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  copyurl(val, flag) {
    console.log(val);
    if (flag == 'copy_file') {
      navigator.clipboard.writeText(val);

    } else if (flag == 'copy_url') {
      let url = 'https://www.videolibrary.betoparedes.com/video/' + val;
      navigator.clipboard.writeText(url);
    }
    this.openSnackBar('Video Url Copy Successfully', 'ok');

  }
  transform(val: any) {
    // console.log(val, 'val video data');
    return this.sanitizer.bypassSecurityTrustResourceUrl(val.video_file_name)
  };

  isActive(val: any) {
    return this.selected === val;
  };

  imageGalleryLoadMore() {
    this.indexVal = this.indexVal + 4;
  };
  editvideo(val: any) {
    console.log('edit video func', val);
    this.router.navigateByUrl('video-library/edit/' + val._id)

  };
  videodelete(val: any) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    const dialogRef = this.dialog.open(deletevideomodal, {

      data: val
    });
    dialogRef.afterClosed().subscribe((result) => {

      console.log(result, 'result');
      // return
      console.log('Delete video', val);
      let data = {
        id: val._id
      }

      if (result === 'true') {
        this.videoloaderflag = true;
        this.Apiservice
          .getDataWithoutToken(data, 'api/deletevideodata')
          .subscribe((res: any) => {
            console.log(res, 'training_reportsfunc**********');
            if (res.status == 'success') {
              this.videoloaderflag = false;
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
              this.openSnackBar('Video Delete Successful.', null);
              let currentUrl = this.router.url;
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate([currentUrl]);
            }
          });
      }
    });
  }
  // viewdetails(val: any) {
  //   console.log(val, 'for view details func');
  //   const dialogRef = this.dialog.open(viewvideodailog, {
  //     height: '600px',
  //     width: '900px',
  //     data: val
  //   });

  // };
  videonotes(val: any) {
    console.log(val, 'for view details func');
    let dialogRef = this.dialog.open(videoaddnotes, {
      height: '600px',
      width: '900px',
      data: val,
      disableClose: true,
      panelClass: 'play-video-modal'
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    });
  }
  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  clickOpenVideo(val) {
    // this.router.navigateByUrl('play_video-library/' + val._id)
    console.log('val', val);
    let dialogRef = this.dialog.open(playvideomodal, {
      height: '600px',
      width: '900px',
      data: val,
      disableClose: true,
      panelClass: 'play-video-modal'
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    });
  }
}


@Component({
  selector: 'viewvideodailog',
  templateUrl: 'viewvideodailog.html',
})
export class viewvideodailog {
  public videodata: any;
  constructor(public dialogRef: MatDialogRef<viewvideodailog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private sanitizer: DomSanitizer,) {
    console.log(data, 'view details dailog data,madhumita');
    this.videodata = data.video_file_name
    console.log(this.videodata, 'qwertyuiop');

    // this.transformsss();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {

  }
  transformsss(val: any) {
    console.log(val, 'val video data');
    return this.sanitizer.bypassSecurityTrustResourceUrl(val)
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}



@Component({
  selector: 'videoaddnotes',
  templateUrl: 'videoaddnotes.html',
})
export class videoaddnotes {
  public addnotes: any;
  public video_id: any;
  public notes: any = [];
  public videoloaderflag: boolean = false;
  color: ThemePalette = 'warn';
  constructor(public dialogRef: MatDialogRef<videoaddnotes>, public Apiservice: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log(data, 'notes dailog data');
    this.video_id = this.data._id;
    this.viewnotes();
  }


  cancelyes() {
    console.log(this.addnotes, 'val video data');
    let data_note_video = {
      note: this.addnotes,
      id: this.data._id
    };
    this.Apiservice.getDataWithoutToken(data_note_video, 'api/addnotes')
      .subscribe((res: any) => {
        console.log(res, 'add notes for video');
        if (res.status === 'success') {
          this.dialogRef.close();
        }

      });
  }
  viewnotes() {
    this.videoloaderflag = true;
    let data_viewnote = {
      id: this.data._id
    };
    this.Apiservice.getDataWithoutToken(data_viewnote, 'api/listnote-data')
      .subscribe((res: any) => {
        console.log(res.res[0], 'add notes for video');
        this.notes = res.res[0].notesdata
        console.log(this.notes, 'notes');
        this.videoloaderflag = false;
        // if (res.status == 'success') {
        //   this.dialogRef.close();
        // }

      });
  };

  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'playvideomodal',
  templateUrl: 'playvideomodal.html',
})
export class playvideomodal {
  video_currenttime: any = 0;
  public video_time: any = '0:0:0';
  videotimeflag: boolean = false;

  public videoJsConfigObj = {
    preload: 'metadata',
    controls: false,
    autoplay: true,
    overrideNative: true,
    techOrder: ['html5', 'flash'],
    html5: {
      nativeVideoTracks: false,
      nativeAudioTracks: false,
      nativeTextTracks: false,
      hls: {
        withCredentials: false,
        overrideNative: true,
        debug: true
      }
    }
  };
  player: videojs.Player;
  selected: any;
  video_url: any = '';
  alldata: any = '';
  video_url1: any = '';
  video_safe_url: any = [];
  video_play_pause: any = [];
  playerid: any = '';
  public playvideoflag: boolean = false;
  video_end_time: any = '0:0:0';
  playpauseflag: boolean = false;
  constructor(public dialogRef: MatDialogRef<playvideomodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private sanitizer: DomSanitizer) {
    console.log(data, 'play video dailog data');
    this.alldata = data;
    // this.cancelyes();

    console.log(this.video_url, 'data');

  }
  // console.log(val);

  // cancelyes() {
  //   console.log(this.data, 'val video data');
  //   // return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video_file_name)
  // };
  ngOnInit() {
    console.log(this.alldata, 'video');
    let url = 'https://d291rlacfzbauk.cloudfront.net/' + this.alldata.video_file.path + this.alldata.video_file.fileservername
    this.video_url1 = '';
    // this.player.dispose();
    this.player = null;
    this.video_url = null;
    this.video_url1 = null;

    //FOR LOAD VIDEO FORM DATA SET
    this.playvideoflag = false;
    this.playvideoflag = true;

    this.video_url = url;
    this.video_url1 = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    console.warn(this.video_url1);

    setTimeout(() => {

      this.player = videojs('#my-video-modal');

      this.player.controls(false); //TO CONTROL FALSE 
      console.log(this.player.duration());
      this.playerid = this.player.id_;
      this.video_currenttime = parseInt(this.player.currentTime());
      this.onprocess();
    }, 1000);
  }
  skip(value) {

    this.player.currentTime(parseInt(this.player.currentTime() + value));


  }
  playbtn() {   //FOR PLAY THE VIDEO
    console.log(this.player);
    this.playpauseflag = true;
    this.onprocess();

    this.player.play();

  }
  pausebtn() {  //FOR PAUSE THE VIDEO.=
    this.playpauseflag = false;
    this.player.pause();
  }

  closemodal() {
    console.log('lllllllllllll');
    // this.player.setCurrentTime(0);
    this.player.dispose();
    this.dialogRef.close();
  }
  video_duration: any = '';
  video_percent: any = 0;

  onprocess() {
    this.video_percent = 0;
    setTimeout(() => {
      this.video_currenttime = parseInt(this.player.currentTime());
      var sec_num = parseInt(this.video_currenttime, 10);
      var hours: any = Math.floor(sec_num / 3600);
      var minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds: any = sec_num - (hours * 3600) - (minutes * 60);
      this.video_time = hours + ':' + minutes + ':' + seconds;
      setTimeout(() => {
        this.video_duration = parseInt(this.player.duration());
        var sec_duration_num = parseInt(this.video_duration, 10);
        var duration_hours: any = Math.floor(sec_duration_num / 3600);
        var duration_minutes: any = Math.floor((sec_duration_num - (duration_hours * 3600)) / 60);
        var duration_seconds: any = sec_duration_num - (duration_hours * 3600) - (duration_minutes * 60);
        // // // // // console.log(val, 'audio_duration')
        this.video_end_time = duration_hours + ':' + duration_minutes + ':' + duration_seconds;
        this.videotimeflag = true;
      }, 500);



      this.video_percent = (this.video_currenttime / this.video_duration) * 100;
    }, 1000);


  };

  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
}

@Component({
  selector: 'deletevideomodal',
  templateUrl: 'deletevideomodal.html',
})
export class deletevideomodal {

  constructor(public dialogRef: MatDialogRef<deletevideomodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) {
    console.log(data, 'view details dailog data,madhumita');



  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}