import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterEvent,
  RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoaderService } from './loader.service';
@Injectable({
  providedIn: 'root',
})
export class ResolveService {
  public userid: any;
  public secret: any;
  public token: any = '';
  public userType: any;
  public userId: any;

  private previousUrl: string;
  private currentUrl: string;
  constructor(
    public apiservice: ApiService,
    public cookie: CookieService,
    public activatedroute: ActivatedRoute,
    public router: Router,
    private loaderService: LoaderService
  ) {
    this.currentUrl = this.router.url;
    this.previousUrl = null;

    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
        console.log('prev: ', this.previousUrl);
        console.log('curr: ', this.currentUrl);
      });

    if (
      this.cookie.check('secret') &&
      this.cookie.check('jwtToken') &&
      this.cookie.check('userid')
    ) {
      this.userid = JSON.parse(this.cookie.get('userid'));
      this.secret = localStorage.getItem('secret');
      this.token = localStorage.getItem('jwtToken');
    }
    if (this.cookie.get('type') != null && this.cookie.get('type') !== '') {
      this.userType = JSON.parse(this.cookie.get('type'));
    }

    if (this.cookie.get('userid') != null && this.cookie.get('userid') !== '') {
      this.userId = JSON.parse(this.cookie.get('userid'));
    }

    if (
      !this.cookie.check('signature') &&
      this.cookie.check('randomstring') &&
      (this.userType === 'sales-person' ||
        this.userType === 'technological-consultant' ||
        this.userType === 'distributor')
    ) {
      // console.log('++++++++ login ====', '/contract/' + JSON.parse(this.cookie.get('randomstring')))
      this.router.navigateByUrl(
        '/contract/' + JSON.parse(this.cookie.get('randomstring'))
      );
    }

    if (
      !this.cookie.check('event_type') &&
      this.cookie.check('signature') &&
      (this.userType === 'sales-person' ||
        this.userType === 'technological-consultant' ||
        this.userType === 'distributor')
    ) {
      this.router.navigateByUrl(
        '/calendar-management/manage-appointment/on-boarding/book-appointment'
      );
    }

    if (
      this.cookie.check('signature') &&
      this.cookie.check('event_type') &&
      this.activatedroute.snapshot.routeConfig != null &&
      this.activatedroute.snapshot.routeConfig.path ===
      'manage-appointment/on-boarding/book-appointment'
    ) {
      this.router.navigateByUrl(this.currentUrl);
    }



  }




  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const _id = route.params._id;
    const endpoint = route.data.link;
    const source = route.data.source;
    const condition = route.data.condition;
    let requestData: any = {};
    console.log(route.data, 'kkk', route.params.id);
    // if (route.params.id) {
    //   route.data.id = route.params.id;
    // }
    if (route.data.requestcondition) {
      requestData = route.data.requestcondition;
      requestData = JSON.stringify(requestData);
      requestData = JSON.parse(requestData);
    } else {
      requestData = route.data;
      requestData = JSON.stringify(requestData);
      requestData = JSON.parse(requestData);
    }


    let alldata = JSON.stringify(route.data);
    route.data = JSON.parse(alldata);
    // console.log(route.data,'++++++__________+++++++++')


    if (requestData && requestData.data && requestData.data._id != null && requestData.data._id !== '') {
      console.log(route.params._id, '++++++++++++++++++++++++++++++++', requestData);
      delete requestData.api_url;
      delete requestData.endpoint;
      requestData.data._id = route.params._id;
    }

    console.log(requestData);

    if (requestData && requestData._id) {
      console.log(route.params._id, '++++++++++++++++++++++++++++++++', requestData);
      delete requestData.api_url;
      delete requestData.endpoint;
      requestData._id = route.params._id;
      requestData.id = route.params._id;

    }
    if (requestData && requestData.id) {
      console.log(route.params._id, '++++++++++++++++++++++++++++++++', requestData);
      delete requestData.api_url;
      delete requestData.endpoint;
      requestData._id = route.params._id;
      requestData.id = route.params._id;
    }


    if (requestData &&
      requestData.secret != null &&
      requestData.secret !== '' &&
      typeof requestData.secret !== 'undefined' &&
      requestData.token !== 'undefined' &&
      requestData.token != null &&
      requestData.token !== '' &&
      this.token !== ''
    ) {
      if (route.data.requestcondition) {
        route.data.requestcondition.secret = this.secret;
        route.data.requestcondition.token = this.token;
      }

    }

    // if (requestData.searchcondition != null && requestData.searchcondition.parentid != undefined && requestData.searchcondition.parentid != null && requestData.searchcondition.parentid != '' && requestData.searchcondition.parentid == 'userid' && this.cookie.check('userid')) {
    //   requestData.searchcondition.parentid = JSON.parse(this.cookie.get('userid'));
    // }

    if (
      requestData &&
      requestData.searchcondition !== null &&
      requestData.searchcondition !== '' &&
      typeof requestData.searchcondition !== 'undefined'

    ) {
      console.log(requestData.searchcondition, '++++++++++++++++');
      if (requestData.searchcondition._id === '_id') {
        requestData.searchcondition._id = JSON.parse(this.cookie.get('userid'));
      }
      if (requestData.id === '_id') {
        console.log('requestData');

        requestData.id = JSON.parse(this.cookie.get('userid'));
      }

      if (requestData.searchcondition.type === 'type') {
        requestData.searchcondition.type = JSON.parse(this.cookie.get('type'));
      }
    }

    if (requestData &&
      requestData.condition !== null &&
      requestData.condition !== '' &&
      typeof requestData.condition !== 'undefined'
    ) {
      requestData.condition = Object.assign(
        requestData.condition,
        route.params
      );
    }

    // console.log(this.activatedroute.snapshot.routeConfig);

    if (
      route.data.endpoint === 'api/addorupdateusers'
    ) {
      requestData.data._id = route.params._id;
    }


    if (
      route.data.endpoint === 'api/update_single_image_video'
    ) {
      requestData.data._id = route.params._id;
    }
    // if (this.activatedroute.snapshot.routeConfig.path == 'manage-appointment/on-boarding/book-appointment') { this.router.navigateByUrl(this.currentUrl); }




    // console.log(route.data.endpoint,'++++++++++')
    this.loaderService.isLoading.next(true);
    return new Promise((resolve) => {
      console.log('true return new Promise', requestData, route.data.endpoint, route.data.api_url);
      this.apiservice
        .customRequest(requestData, route.data.endpoint, route.data.api_url)
        .subscribe((apiobject) => {
          // // console.log('api_object',apiobject);
          if (apiobject) {
            // // console.log('false --------- return new Promise')
            this.loaderService.isLoading.next(false);
            return resolve(apiobject);
          } else {
            // // console.log('false return new Promise');
            this.loaderService.isLoading.next(false);
            return true;
          }
        });
    });
  }
}
