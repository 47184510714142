import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material';
import { LoaderService } from '../../services/loader.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  loading: boolean;
  color: ThemePalette = 'warn';
  constructor(public loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      console.log('this.loading', this.loading);
    });

  }
  ngOnInit() {
  }
}
