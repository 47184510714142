import {
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  ViewChild,
} from '@angular/core';
import { switchMap, map, takeWhile, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../environments/environment';
// import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private cookieservice: CookieService,
    public router: Router,
    public activatedroute: ActivatedRoute,
    private snackbar: MatSnackBar
  ) {
    // if (this.cookieservice.check('jwtToken')) {
    //   this.jwttoken = this.cookieservice.get('jwtToken');
    // }

    // if (this.cookieservice.check('secret')) {
    //   this.secret = this.cookieservice.get('secret');
    // }
  }
  public api_url = environment.api_url;
  // public domain_url = environment.domain_url;
  public fileimgsslurl: any;
  fileservername: any = [];
  serverUrl: any;
  addendpointUrl: any;
  uploadEndpointUrl: any;
  updateendpointUrl: any;
  updatestatussingleendpointUrl: any;
  deletemultipleendpointUrl: any;
  updatestatusmultipleendpointUrl: any;
  getdataendpointUrl: any;
  public invalidApi: any;
  public jwttoken = '';
  public tokenVal: any;
  public updatestatussingleendpointurl: any;
  public deletesingle_endpointUrl: any;
  public deletesingleendpointUrl: any;

  public secret: string;
  public count = 3;

  isTokenExpired() { }

  /* read site setting data */
  public getSiteSettingData(url): Observable<any> {
    return this.http.get(url);
  }
  /* read Json data */
  public getJsonData(url): Observable<any> {
    return this.http.get(url);
  }



  getDataWithoutToken(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }
   getDatafileupload(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }
  getDatafileuploadput(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .put(endpoint, requestdata, httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getDataWithoutTokenapi(val) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .get(val, httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }


  customRequest(requestdata: any, endpoint: any, url: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // Authorization: this.cookieService.get('jwtToken')
      }),
    };
    const result = this.http
      .post(url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }



  gettemptoken() {
    const result = this.http
      .get(this.api_url + 'gettemptoken')
      .pipe(map((res) => res));
    return result;
  }

  getJsonObject(path: any) {
    const result = this.http.get(path).pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }


  timezone() {
    const result = this.http.get('assets/data/timezone.json').pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }



  getState() {
    const result = this.http.get('assets/data/states.json').pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }

  getCountry() {
    const result = this.http.get('assets/data/countries.json').pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }
  httpViaPost(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url + requestdata.endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }



  openSnackBar() {
    this.snackbar.open('Something Went Wrong ,Try Again!!', 'ok', {
      duration: 6000,
    });
  }
}
