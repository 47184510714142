import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import videojs from 'video.js';
import { MatSnackBar } from '@angular/material';

type NewType = boolean;

type NewType_1 = boolean;

@Component({
  selector: 'app-play-video',
  templateUrl: './play-video.component.html',
  styleUrls: ['./play-video.component.css']
})
export class PlayVideoComponent implements OnInit {
  public video_listtable: any = [];
  public exitfullscreenflag: NewType = false;
  video_url: any;
  video_url1: any;
  // player: videojs.Player;
  public playvideoflag: NewType_1 = false;
  video_currenttime: any = 0;
  public video_time: any = '0:0:0';
  public playpauseflag: NewType = false;

  public show: string = '';
  public Change_video_percent: any = 0;
  public videoJsConfigObj = {
    preload: 'metadata',
    controls: false,
    autoplay: true,
    overrideNative: true,
    techOrder: ['html5', 'flash'],
    html5: {
      nativeVideoTracks: false,
      nativeAudioTracks: false,
      nativeTextTracks: false,
      hls: {
        withCredentials: false,
        overrideNative: true,
        debug: true
      }
    }
  };
  player: videojs.Player;
  selected: any;
  alldata: any = '';
  video_safe_url: any = [];
  video_play_pause: any = [];
  playerid: any = '';
  video_end_time: any = '0:0:0';
  videoplayflag: boolean = false;
  videotimeflag: boolean = false;
  public addnotes: boolean = false;
  public addnotesdata: any = '';
  public disabled: any = false;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private renderer: Renderer2
  ) {
    console.log(activatedRoute.snapshot.url[0].path);
    if (activatedRoute.snapshot.url[0].path === 'video') {
      this.playvideoflag = true;
    }
  }

  ngOnInit() {

    this.activatedRoute.data.forEach((res: any) => {
      console.log(res, 'video data');

      this.video_listtable = res.resolvedata.res[0]; // data store from backend
      console.log(this.video_listtable, 'video data');
      let url = 'https://d291rlacfzbauk.cloudfront.net/' + this.video_listtable.video_file.path + this.video_listtable.video_file.fileservername;
      this.video_url = url;
      this.video_url1 = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
      // this.videoplayflag = true;
      if (this.playvideoflag !== true) {
        setTimeout(() => {
          let videoPlayer = <HTMLVideoElement>document.getElementById('vid');
          videoPlayer.muted = true;

          videoPlayer.play();


        }, 3000);
      }

      this.videoplayflag = true;

      setTimeout(() => {


        this.player = videojs('#my-video-modal');

        this.player.controls(false); // TO CONTROL FALSE
        console.log(this.player.duration());
        this.playerid = this.player.id_;
        this.video_currenttime = parseInt(this.player.currentTime());
        this.onprocess();
      }, 1000);


    });
  }
  copyurl() {
    const url = 'https://www.videolibrary.betoparedes.com/video/' + this.activatedRoute.snapshot.params.id;
    navigator.clipboard.writeText(url);
    this.openSnackBar('Video Url Copy Successfully', 'ok');
  }
  skip(value) {
    this.player.currentTime(parseInt(this.player.currentTime() + value));
    console.log('currentTime', this.player.currentTime());
  }
  playbtn() {   // FOR PLAY THE VIDEO
    console.log(this.player);
    this.playpauseflag = true;

    this.onprocess();

    this.player.play();
    console.log(this.player.cache_.currentTime, this.player.cache_.duration);

  }
  pausebtn() {  //FOR PAUSE THE VIDEO.
    console.log(this.player.cache_.currentTime, this.player.cache_.duration);
    this.playpauseflag = false;

    this.player.pause();
  }

  // fullscreenview(flag) {

  //   console.log(flag);
  //   if (flag === 'fullscreen') {
  //     this.exitfullscreenflag = true;
  //     this.player.requestFullscreen();
  //     this.show ="red";
  //   } else {
  //     this.exitfullscreenflag = false;
  //   }
  // }

  fullscreenview() {
    let elem = document.querySelector("video");
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch();
    } else {
      document.exitFullscreen();
    }
  }

  public video_duration: any = '';
  video_percent: any = 0;
  onprocess() {
    setTimeout(() => {
      this.video_currenttime = parseInt(this.player.currentTime());
      const sec_num = parseInt(this.video_currenttime, 10);
      const hours: any = Math.floor(sec_num / 3600);
      const minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
      const seconds: any = sec_num - (hours * 3600) - (minutes * 60);
      this.video_time = hours + ':' + minutes + ':' + seconds;
      setTimeout(() => {
        this.video_duration = parseInt(this.player.duration());
        const sec_duration_num = parseInt(this.video_duration, 10);
        const duration_hours: any = Math.floor(sec_duration_num / 3600);
        const duration_minutes: any = Math.floor((sec_duration_num - (duration_hours * 3600)) / 60);
        const duration_seconds: any = sec_duration_num - (duration_hours * 3600) - (duration_minutes * 60);
        this.video_end_time = duration_hours + ':' + duration_minutes + ':' + duration_seconds;
        this.videotimeflag = true;
      }, 500);

      console.log(this.video_time, 'audio_duration', this.video_end_time)


      this.video_percent = (this.video_currenttime / this.video_duration) * 100;
    }, 1000);


  }

  cancelyes() {
    console.log(this.activatedRoute.snapshot.params.id);
    let data_note_video = {
      note: this.addnotesdata,
      id: this.activatedRoute.snapshot.params.id
    };
    this.Apiservice.getDataWithoutToken(data_note_video, 'api/addnotes')
      .subscribe((res: any) => {
        console.log(res, 'add notes for video');
        if (res.status === 'success') {
          this.addnotes = false;
          setTimeout(() => {
            const data = { condition: { id: this.activatedRoute.snapshot.params.id } }
            this.Apiservice.getDataWithoutToken(data, 'api2/singlevideo')
              .subscribe((res: any) => {
                this.video_listtable = res.res[0];
                this.addnotesdata = '';
              });
            // const currentUrl = this.router.url;
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = 'reload';
            // this.router.navigate([currentUrl]);
          }, 1000);
        }

      });
  }

  progressvideo(val) {
    let video_time: any;
    video_time = (this.Change_video_percent * this.video_duration) / 100;

    const sec_num = parseInt(video_time, 10);
    const hours: any = Math.floor(sec_num / 3600);
    const minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
    const seconds: any = sec_num - (hours * 3600) - (minutes * 60);
    this.video_time = hours + ':' + minutes + ':' + seconds;

    console.log(this.video_duration, this.video_percent, this.Change_video_percent, this.video_time);

    this.player.currentTime(parseInt(video_time));

  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.player.dispose();
  }
  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  addnotesfunc() {
    this.addnotes = true;
    // document.getElementById('addnotes').scrollIntoView({ behavior: 'smooth' });
  }
}
