import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-videobucketlist',
  templateUrl: './videobucketlist.component.html',
  styleUrls: ['./videobucketlist.component.css']
})
export class VideobucketlistComponent implements OnInit {
  public all_data: any = [];
  public videoloaderflag:any;
  public indexVal: any = 12;

  constructor(public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      console.log(res.resolvedata.result.res);
      this.all_data = res.resolvedata.result.res;
    })
  }
  copyurl(val) {
    navigator.clipboard.writeText(val);
    this.openSnackBar('Video Url Copy Successfully', 'ok')
  }
  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  };
  imageGalleryLoadMore(){

  }
}
